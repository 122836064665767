import { API_REQUEST } from '@/store/middlewares/apiMiddleware';
import { createApiType, getApiTypeList } from './typesBuilder';

export const Types = {
  ...createApiType([
    'KNOWLEDGE_LIST',
    'ANNOUNCE_LIST',
  ]),
  // ...createType([
  //   'ACCOUNT_LOGOUT',
  // ]),
};

export function requestKnowledgeList({ onSuccess, onFailed }) {
  return {
    [API_REQUEST]: {
      types: getApiTypeList('KNOWLEDGE_LIST'),
      data: {},
      endpoint: '/articles/',
      dispatchPayload: null,
      auth: false,
      json: true,
      method: 'GET',
      onSuccess,
      onFailed,
    },
  };
}

export function requestAnnounceList({ onSuccess, onFailed }) {
  return {
    [API_REQUEST]: {
      types: getApiTypeList('ANNOUNCE_LIST'),
      data: {},
      endpoint: '/announces/',
      dispatchPayload: null,
      auth: false,
      json: true,
      method: 'GET',
      onSuccess,
      onFailed,
    },
  };
}
