import { Types } from '@/store/actions/account';

const initState = {
  token: null,
  tokenInfo: null,
  info: null,
};

export default (state = initState, action) => {
  switch (action.type) {
    case Types.SET_GAME_ONE_TYPE_SUCCESS: {
      const { payload: { data } } = action;
      return {
        ...state,
        info: data,
      };
    }
    case Types.FETCH_USER_INFO_SUCCESS: {
      const { payload: { data } } = action;
      return {
        ...state,
        info: data,
      };
    }
    case Types.RESET_ACCOUNT_INFO: {
      const { payload: { token, detail } } = action;
      return {
        ...state,
        token,
        tokenInfo: detail,
        info: detail.data,
      };
    }
    case Types.ACCOUNT_LOGOUT:
      return initState;
    default:
      return state;
  }
};
