import _get from 'lodash/get';
import omit from 'lodash/omit';
import isArray from 'lodash/isArray';
import requestAgent from '@/store/requestAgent';
import { getToken } from '@/utils/storageHelper';

export const API_REQUEST = Symbol('API_REQUEST');
export const NO_TOKEN_STORED = Symbol('NO_TOKEN_STORED');

function ApiError(error) {
  this.name = 'ApiError';
  this.data = error ?? '';
  this.stack = (new Error()).stack;
}

ApiError.prototype = Object.create(Error.prototype);
ApiError.prototype.constructor = ApiError;

export default store => next => async (action) => {
  const requestOptions = action[API_REQUEST];
  if (typeof requestOptions === 'undefined') {
    return next(action);
  }

  const {
    method,
    endpoint,
    params,
    data,
    uri,
    types,
    auth,
    json,
    headers = [],
    onSuccess,
    onFailed,
  } = requestOptions;

  const dispatchPayload = omit((requestOptions.dispatchPayload ?? {}), 'type');


  if (!isArray(types) || types.length !== 3) {
    throw new Error('Expected an array of three action types.');
  }

  if (!types.every(type => typeof type === 'string')) {
    throw new Error('Expected action types to be strings.');
  }

  const [requestType, successType, errorType] = types;

  // // Inject JWT Token
  if (auth) {
    const token = getToken();
    if (token) {
      headers.push({
        key: 'Authorization',
        value: `Bearer ${token}`,
      });
    } else {
      return next({
        type: NO_TOKEN_STORED,
      });
    }
  }

  // ContentType
  if (json) {
    headers.push({ key: 'Content-Type', value: 'application/json' });
  }

  try {
    // Before Request
    (requestType) && next({ type: requestType, ...dispatchPayload });
    const response = await requestAgent.request({
      method,
      endpoint,
      params,
      data: json
        ? data
        : Object.entries(data)
          .reduce(
            (res, [key, value]) =>
              `${res}&${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
            '',
          )
          .substr(1),
      uri,
      headers: headers.reduce((res, item) => ({
        ...res,
        [item.key]: item.value,
      }), {}),
    });
    /**
     * SUCCESS response specific processing
     */

    if (isArray(response.data)) {
      const res = { list: response.data };
      (onSuccess) && onSuccess(res);
      next({ type: successType, ...dispatchPayload, payload: res });
    } else {
      // TEMP
      if (
        response.data
        && response.data.status
        && response.data.status !== 'success'
      ) {
        throw new ApiError(response.data);
      }
      (onSuccess) && onSuccess(response.data);
      next({ type: successType, ...dispatchPayload, payload: response.data });
    }
  } catch (error) {
    // if (_get(error, 'response.status') === 401) {
    //   logout();
    // } else {
    //   // other error
    //   (onFailed) && onFailed(error);
    //   (errorType) && next({ type: errorType, error, ...dispatchPayload });
    // }

    // other error
    (onFailed) && onFailed(_get(error, 'response.data.error', error));
    (errorType) && next({ type: errorType, error, ...dispatchPayload });

    return console.error(error);
  }

  return true;
};
