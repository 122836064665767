import { createBrowserHistory } from 'history';
import prodStore from './configStore.prod';
import devStore from './configStore.dev';

export const history = createBrowserHistory();

const config = (process.env.REACT_APP_ENV === 'production')
  ? prodStore(history)
  : devStore(history);

export default config();
