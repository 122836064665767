import _get from 'lodash/get';
import { connect } from 'react-redux';

const stateToProps = (state) => {
  return {
    isLoggedIn: !!_get(state, 'Account.token'),
  };
};

const actionsToProps = dispatch => ({
});

export default Component => connect(
  stateToProps,
  actionsToProps,
)(Component);
