import React from 'react';
import T from 'prop-types';
import {
  Switch,
  Redirect,
  Route,
  useLocation,
} from 'react-router-dom';
import { removeItem, setItem } from '@/utils/storageHelper';
import loadable from '@loadable/component';
import { logout } from '@/operations/account';
import withLogin from '@/resources/withLogin';

// Home Page
const HomePage = loadable(
  () => import(/*  webpackPrefetch: true, webpackChunkName: "Home" */ '@/pages/Home').then(({ Home }) => Home),
);
// Game1 Page
const Game1Index = loadable(
  () => import(/*  webpackPrefetch: true, webpackChunkName: "game1" */ '@/pages/Game1').then(({ Game1 }) => Game1),
);
const Game1Personal = loadable(
  () => import(/*  webpackPrefetch: true, webpackChunkName: "game1-personal" */ '@/pages/Game1').then(({ Personal }) => Personal),
);
const Game1Team = loadable(
  () => import(/*  webpackPrefetch: true, webpackChunkName: "game1-team" */ '@/pages/Game1').then(({ Team }) => Team),
);
const StartGame1 = loadable(
  () => import(/*  webpackPrefetch: true, webpackChunkName: "game1-start" */ '@/pages/Game1').then(({ StartGame }) => StartGame),
);

// Game2 Page
const Game2Index = loadable(
  () => import(/*  webpackPrefetch: true, webpackChunkName: "game2" */ '@/pages/Game2').then(({ Game2 }) => Game2),
);
const Game2Participate = loadable(
  () => import(/*  webpackPrefetch: true, webpackChunkName: "game2-participate" */ '@/pages/Game2').then(({ Participate }) => Participate),
);
const StartGame2 = loadable(
  () => import(/*  webpackPrefetch: true, webpackChunkName: "game2-start" */ '@/pages/Game2').then(({ StartGame }) => StartGame),
);

// Game3 Page
const Game3Index = loadable(
  () => import(/*  webpackPrefetch: true, webpackChunkName: "game2" */ '@/pages/Game3').then(({ Game3 }) => Game3),
);
const Game3Participate = loadable(
  () => import(/*  webpackPrefetch: true, webpackChunkName: "game2-participate" */ '@/pages/Game3').then(({ Participate }) => Participate),
);
const Game3Area = loadable(
  () => import(/*  webpackPrefetch: true, webpackChunkName: "game2-participate" */ '@/pages/Game3').then(({ Area }) => Area),
);
const Game3CatchMonster = loadable(
  () => import(/*  webpackPrefetch: true, webpackChunkName: "game2-participate" */ '@/pages/Game3').then(({ CatchMonster }) => CatchMonster),
);
const Game3Success = loadable(
  () => import(/*  webpackPrefetch: true, webpackChunkName: "game2-participate" */ '@/pages/Game3').then(({ Success }) => Success),
);
const Game3Failure1 = loadable(
  () => import(/*  webpackPrefetch: true, webpackChunkName: "game2-participate" */ '@/pages/Game3').then(({ Failure1 }) => Failure1),
);
const Game3Failure2 = loadable(
  () => import(/*  webpackPrefetch: true, webpackChunkName: "game2-participate" */ '@/pages/Game3').then(({ Failure2 }) => Failure2),
);

// Article
const AnnounceListPage = loadable(
  () => import(/* webpackPrefetch: true, webpackChunkName: "announce-list" */ '@/pages/Articles').then(({ AnnounceList }) => AnnounceList),
);
const AnnounceDetailPage = loadable(
  () => import(/* webpackPrefetch: true, webpackChunkName: "announce-detail" */ '@/pages/Articles').then(({ AnnounceDetail }) => AnnounceDetail),
);
const KnowledgeListPage = loadable(
  () => import(/* webpackPrefetch: true, webpackChunkName: "knowledge-list" */ '@/pages/Articles').then(({ KnowledgeList }) => KnowledgeList),
);
const KnowledgeDetailPage = loadable(
  () => import(/* webpackPrefetch: true, webpackChunkName: "knowledge-detail" */ '@/pages/Articles').then(({ KnowledgeDetail }) => KnowledgeDetail),
);

// Member
const LoginPage = loadable(
  () => import(/* webpackPrefetch: true, webpackChunkName: "member-login" */ '@/pages/Member').then(({ Login }) => Login),
);
const ResultPage = loadable(
  () => import(/* webpackPrefetch: true, webpackChunkName: "member-result" */ '@/pages/Member').then(({ Result }) => Result),
);
const RecommendPage = loadable(
  () => import(/* webpackPrefetch: true, webpackChunkName: "member-recommend" */ '@/pages/Member').then(({ Recommend }) => Recommend),
);

const LoginLineCbPage = loadable(
  () => import(/* webpackPrefetch: true, webpackChunkName: "test-login" */ '@/pages/Member').then(({ LoginLineCb }) => LoginLineCb),
);
const LoginAppleCbPage = loadable(
  () => import(/* webpackPrefetch: true, webpackChunkName: "test-login" */ '@/pages/Member').then(({ LoginAppleCb }) => LoginAppleCb),
);

const haveToLogin = (isLogin, Component, pathname) => {
  if (isLogin) {
    return <Component />;
  }

  setItem('redirectUrl', pathname);
  return <Redirect to="/login" />;
};

const Routes = React.memo(({ isLoggedIn }) => {
  const { pathname } = useLocation();
  if (!/^\/(auth|login)/.test(pathname)) {
    removeItem('redirectUrl');
  }
  return (
    <Switch>
      <Route key="home" exact path="/" component={HomePage} />

      <Route
        key="game1"
        exact
        path="/game1"
        component={Game1Index}
      />
      <Route
        key="game1Personal"
        exact
        path="/game1/personal"
        component={Game1Personal}
      />
      <Route
        key="game1Team"
        exact
        path="/game1/team"
        component={Game1Team}
      />
      <Route
        key="game1PersonalStartByApp"
        exact
        path="/game1/personal/startByApp"
        render={() => haveToLogin(isLoggedIn, StartGame1, pathname)}
      />
      <Route
        key="game1PersonalStartByLine"
        exact
        path="/game1/personal/startByLine"
        render={() => haveToLogin(isLoggedIn, StartGame1, pathname)}
      />
      <Route
        key="game1TeamStart"
        exact
        path="/game1/team/startByApp"
        render={() => haveToLogin(isLoggedIn, StartGame1, pathname)}
      />

      <Route
        key="announces-list"
        exact
        path="/announces"
        component={AnnounceListPage}
      />
      <Route
        key="announces-article"
        exact
        path="/announces/:announceId"
        component={AnnounceDetailPage}
      />
      <Route
        key="knowledges-list"
        exact
        path="/knowledges"
        component={KnowledgeListPage}
      />
      <Route
        key="knowledges-article"
        exact
        path="/knowledges/:knowledgeId"
        component={KnowledgeDetailPage}
      />

      <Route
        key="game2"
        exact
        path="/game2"
        component={Game2Index}
      />
      <Route
        key="game2Participate"
        exact
        path="/game2/participate"
        component={Game2Participate}
      />
      <Route
        key="game2Start"
        exact
        path="/game2/startByApp"
        render={() => haveToLogin(isLoggedIn, StartGame2, pathname)}
      />

      <Route
        key="game3"
        exact
        path="/game3"
        component={Game3Index}
      />
      <Route
        key="game3Participate"
        exact
        path="/game3/participate"
        component={Game3Participate}
      />
      <Route
        key="game3Area"
        exact
        path="/game3/area/:areaId"
        render={() => haveToLogin(isLoggedIn, Game3Area, pathname)}
      />
      <Route
        exact
        path="/game3/start"
        render={() => haveToLogin(isLoggedIn, Game3CatchMonster, pathname)}
      />
      <Route
        exact
        path="/game3/success"
        render={() => haveToLogin(isLoggedIn, Game3Success, pathname)}
      />
      <Route
        exact
        path="/game3/failure1"
        render={() => haveToLogin(isLoggedIn, Game3Failure1, pathname)}
      />
      <Route
        exact
        path="/game3/failure2"
        render={() => haveToLogin(isLoggedIn, Game3Failure2, pathname)}
      />

      <Route
        key="result"
        exact
        path="/result"
        render={() => haveToLogin(isLoggedIn, ResultPage, pathname)}
      />
      <Route
        key="resultRecommendList"
        exact
        path="/result/game2/recommend/:areaId"
        render={() => haveToLogin(isLoggedIn, RecommendPage, pathname)}
      />

      <Route
        key="prize"
        exact
        path="/prize"
        render={() => <Redirect to="/announces/0" />}
      />

      {!isLoggedIn && (<Route key="login" exact path="/login" component={LoginPage} />)}

      <Route key="auth-line-cb" exact path="/auth/line" component={LoginLineCbPage} />
      <Route key="auth-apple-cb" exact path="/auth/apple" component={LoginAppleCbPage} />
      <Route
        key="logout"
        exact
        path="/logout"
        render={() => {
          logout();
          return <Redirect push={false} to="/" />;
        }}
      />
      <Route key="no-match" render={props => (<Redirect to="/" />)} />
    </Switch>
  );
});
Routes.propTypes = {
  isLoggedIn: T.bool,
};
Routes.defaultProps = {
  isLoggedIn: false,
};

export default Routes |> withLogin;
