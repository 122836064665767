export const clear = () => {
  window.localStorage.clear();
  // delete all cookies
  const cookies = document.cookie.split(';');
  cookies.forEach((cookie) => {
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
  });
};

export const removeItem = key => window.localStorage.removeItem(key);

export const getItem = key => window.localStorage.getItem(key);

export const setItem = (key, value) => window.localStorage.setItem(key, value);


export const setToken = token => setItem('token', token);

export const getToken = () => (getItem('token') ?? null);

export default null;
