import { API_REQUEST } from '@/store/middlewares/apiMiddleware';
import { createApiType, getApiTypeList } from './typesBuilder';

export const Types = {
  ...createApiType([
    'FETCH_GAME_TIME',
    'FETCH_AREA_INFO',
  ]),
  // ...createType([
  //   'ACCOUNT_LOGOUT',
  // ]),
};

export function requestGameTime({ onSuccess, onFailed }) {
  return {
    [API_REQUEST]: {
      types: getApiTypeList('FETCH_GAME_TIME'),
      data: {},
      endpoint: '/games',
      dispatchPayload: null,
      auth: false,
      json: true,
      method: 'GET',
      onSuccess,
      onFailed,
    },
  };
}

export function requestAreaInfo({ onSuccess, onFailed }) {
  return {
    [API_REQUEST]: {
      types: getApiTypeList('FETCH_AREA_INFO'),
      data: {},
      endpoint: '/areas',
      dispatchPayload: null,
      auth: false,
      json: true,
      method: 'GET',
      onSuccess,
      onFailed,
    },
  };
}
