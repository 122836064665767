import { createStore, compose, applyMiddleware } from 'redux';
import reducers from './reducers';
import middlewares from './middlewares';


const {
  routerMiddleware, scrollToTopMiddleware, thunk, apiMiddleware,
} = middlewares;

export default (history) => (preloadState = {}) => {
  const store = createStore(
    reducers(history),
    preloadState,
    compose(
      applyMiddleware(
        thunk,
        apiMiddleware,
        routerMiddleware(history),
        scrollToTopMiddleware,
      ),
    ),
  );
  return store;
};
