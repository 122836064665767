import {
  ifElse, type, merge, reduce, map, concat, compose,
} from 'ramda';

const typeBuilder = key => ({ [key]: key });
const toUpperCase = str => str.toUpperCase();
const mergeElems = elems => ifElse(
  () => type(elems[0]) === 'Array',
  () => reduce(concat, [], elems),
  () => reduce(merge, {}, elems),
)();
const apiTypeListBuilder = key => [`${key}_FETCHING`, `${key}_SUCCESS`, `${key}_FAILED`];

const upperCaseList = stringList => map(toUpperCase, stringList);
const typesBuilder = keys => map(typeBuilder, keys);
const apiTypesBuilder = types => map(apiTypeListBuilder, types);

export const createType = (
  typeList = [],
) => compose(mergeElems, typesBuilder, upperCaseList)(typeList);


export const createApiType = (
  typeList = [],
) => compose(createType, mergeElems, apiTypesBuilder)(typeList);

export const getApiTypeList = apiType => apiTypeListBuilder(apiType);
