
import store from '@/store';
import {
  requestGameTime as _requestGameTime,
  requestAreaInfo as _requestAreaInfo,
} from '@/store/actions/game';

const { dispatch } = store;

export const requestGameTime = ({
  onSuccess = () => {},
  onFailed = () => {},
}) => {
  dispatch(_requestGameTime({
    onSuccess,
    onFailed,
  }));
};

export const requestAreaInfo = ({
  onSuccess = () => {},
  onFailed = () => {},
}) => {
  dispatch(_requestAreaInfo({
    onSuccess,
    onFailed,
  }));
};

export const requestGameInitInfo = ({
  onSuccess = () => {},
  onFailed = () => {},
}) => {
  Promise
    .all([
      new Promise((res, rej) => requestGameTime({ onSuccess: res, onFailed: rej })),
      new Promise((res, rej) => requestAreaInfo({ onSuccess: res, onFailed: rej })),
    ])
    .then(onSuccess)
    .catch(onFailed);
};

export default null;
