import _get from 'lodash/get';
import jwt from 'jsonwebtoken';
import { API_REQUEST } from '@/store/middlewares/apiMiddleware';
import { getToken, setToken, clear } from '@/utils/storageHelper';
import { createType, createApiType, getApiTypeList } from './typesBuilder';

export const Types = {
  ...createApiType([
    'ACCOUNT_LOGIN',
    'VERIFY_LINE_AUTH_CODE',
    'VERIFY_APPLE_AUTH_CODE',
    'FETCH_USER_INFO',
    'SET_GAME_ONE_TYPE',
    'CHECK_IN',
  ]),
  ...createType([
    'RESET_ACCOUNT_INFO',
    'ACCOUNT_LOGOUT',
  ]),
};

export const accountLogout = () => (dispatch) => {
  clear();
  dispatch({ type: Types.ACCOUNT_LOGOUT });
};

export const resetAccountInfoFromLocalStorage = () => (dispatch, getState) => {
  const token = getToken();
  if (token) {
    const detail = jwt.decode(token);
    if (_get(detail, 'exp') * 1000 > Date.now()) {
      dispatch({
        type: Types.RESET_ACCOUNT_INFO,
        payload: { token, detail },
      });
    } else {
      clear();
    }
  }
};

export function requestEmailLogin({
  loginId,
  password,
  onSuccess,
  onFailed,
}) {
  return {
    [API_REQUEST]: {
      types: getApiTypeList('ACCOUNT_LOGIN'),
      data: { loginId, password },
      endpoint: '/auth/login/email',
      headers: [
        {
          key: 'Content-Type',
          value: 'application/x-www-form-urlencoded',
        },
      ],
      dispatchPayload: null,
      params: null,
      auth: false,
      method: 'POST',
      onSuccess: (data) => {
        setToken(data.data.jwt);
        onSuccess(data);
      },
      onFailed,
    },
  };
}

export function requestFacebookLogin({
  loginId,
  onSuccess,
  onFailed,
}) {
  return {
    [API_REQUEST]: {
      types: getApiTypeList('ACCOUNT_LOGIN'),
      data: { loginId },
      endpoint: '/auth/login/facebook',
      dispatchPayload: null,
      params: null,
      auth: false,
      json: true,
      method: 'POST',
      onSuccess: ({ data }) => {
        setToken(data.jwt);
        onSuccess(data);
      },
      onFailed,
    },
  };
}

export function requestGoogleLogin({
  loginId,
  onSuccess,
  onFailed,
}) {
  return {
    [API_REQUEST]: {
      types: getApiTypeList('ACCOUNT_LOGIN'),
      data: { loginId },
      endpoint: '/auth/login/google',
      dispatchPayload: null,
      params: null,
      auth: false,
      json: true,
      method: 'POST',
      onSuccess: ({ data }) => {
        setToken(data.jwt);
        onSuccess(data);
      },
      onFailed,
    },
  };
}

export function requestVerifyLineAuthCode({
  code,
  onSuccess,
  onFailed,
}) {
  return {
    [API_REQUEST]: {
      types: getApiTypeList('VERIFY_LINE_AUTH_CODE'),
      data: null,
      endpoint: '/auth/line',
      dispatchPayload: null,
      params: { code },
      auth: false,
      json: true,
      method: 'GET',
      onSuccess,
      onFailed,
    },
  };
}

export function requestLineLogin({
  loginId,
  onSuccess,
  onFailed,
}) {
  return {
    [API_REQUEST]: {
      types: getApiTypeList('ACCOUNT_LOGIN'),
      data: { loginId },
      endpoint: '/auth/login/line',
      dispatchPayload: null,
      params: null,
      auth: false,
      json: true,
      method: 'POST',
      onSuccess: ({ data }) => {
        setToken(data.jwt);
        onSuccess(data);
      },
      onFailed,
    },
  };
}

export function requestVerifyAppleAuthCode({
  code,
  onSuccess,
  onFailed,
}) {
  return {
    [API_REQUEST]: {
      types: getApiTypeList('VERIFY_APPLE_AUTH_CODE'),
      data: null,
      endpoint: '/auth/apple',
      dispatchPayload: null,
      params: { code },
      auth: false,
      json: true,
      method: 'GET',
      onSuccess,
      onFailed,
    },
  };
}

export function requestAppleLogin({
  loginId,
  onSuccess,
  onFailed,
}) {
  return {
    [API_REQUEST]: {
      types: getApiTypeList('ACCOUNT_LOGIN'),
      data: { loginId },
      endpoint: '/auth/login/apple',
      dispatchPayload: null,
      params: null,
      auth: false,
      json: true,
      method: 'POST',
      onSuccess: ({ data }) => {
        setToken(data.jwt);
        onSuccess(data);
      },
      onFailed,
    },
  };
}

export function requestUserInfo({
  loginId,
  onSuccess,
  onFailed,
}) {
  return {
    [API_REQUEST]: {
      types: getApiTypeList('FETCH_USER_INFO'),
      data: { loginId },
      endpoint: '/users/me',
      dispatchPayload: null,
      params: null,
      auth: true,
      json: true,
      method: 'GET',
      onSuccess,
      onFailed,
    },
  };
}

export function requestSetGameOneType({
  type,
  onSuccess,
  onFailed,
}) {
  return {
    [API_REQUEST]: {
      types: getApiTypeList('SET_GAME_ONE_TYPE'),
      data: { type },
      endpoint: '/users/set-game-one-type',
      dispatchPayload: null,
      params: null,
      auth: true,
      json: true,
      method: 'POST',
      onSuccess,
      onFailed,
    },
  };
}

export function requestCheckIn({
  landmarkId,
  onSuccess,
  onFailed,
}) {
  return {
    [API_REQUEST]: {
      types: getApiTypeList('CHECK_IN'),
      data: { landmarkId },
      endpoint: '/users/check-in',
      dispatchPayload: null,
      params: null,
      auth: true,
      json: true,
      method: 'POST',
      onSuccess,
      onFailed,
    },
  };
}
