import { createLogger as logger } from 'redux-logger';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import scrollToTopMiddleware from './scrollToTopMiddleware';
import apiMiddleware from './apiMiddleware';

export default {
  apiMiddleware,
  routerMiddleware,
  scrollToTopMiddleware,
  logger,
  thunk,
};
