import _get from 'lodash/get';
import { Types } from '@/store/actions/game';

const initState = {
  gameTime: [
    { start: '20200727', end: '20201031' },
    { start: '20200727', end: '20200930' },
    { start: '20200901', end: '20201031' },
  ],
  landmarks: [],
  areaList: [],
};

export default (state = initState, action) => {
  switch (action.type) {
    case Types.FETCH_GAME_TIME_SUCCESS: {
      return {
        ...state,
        gameTime: [
          _get(action, 'payload.data.gameOne'),
          _get(action, 'payload.data.gameTwo'),
          _get(action, 'payload.data.gameThree'),
        ],
      };
    }
    // case Types.FETCH_GAME_TIME_FETCHING:
    // case Types.FETCH_GAME_TIME_FAILED: {
    //   return { ...state, gameTime: [] };
    // }
    case Types.FETCH_AREA_INFO_SUCCESS: {
      return {
        ...state,
        landmarks: _get(action, 'payload.data.landmarks'),
        areaList: _get(action, 'payload.data.adminAreas'),
      };
    }
    case Types.FETCH_AREA_INFO_FETCHING:
    case Types.FETCH_AREA_INFO_FAILED: {
      return {
        ...state,
        landmarks: [],
        areaList: [],
      };
    }
    default:
      return state;
  }
};
