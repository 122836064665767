import axios from 'axios';

const API_HOST = process.env.REACT_APP_API_HOST;

function upload({
  file, url = API_HOST, headers = [{ key: '', value: '' }],
}) {
  return new Promise((resolve, reject) => {
    const fd = new FormData();
    fd.append('file', file);
    const xhr = new window.XMLHttpRequest();
    xhr.withCredentials = true;
    xhr.open('POST', url);
    headers.forEach((header) => {
      xhr.setRequestHeader(header.key, header.value);
    });
    try {
      xhr.send(fd);
      xhr.addEventListener('readystatechange', function () {
        if (this.readyState === 4) {
          resolve(JSON.parse(this.responseText));
        }
      });
    } catch (ex) {
      reject(ex);
    }
  });
}

export default {
  request: ({
    baseURL = API_HOST,
    method = 'get',
    endpoint = '',
    params = {},
    data = {},
    uri = '',
    headers = {},
  }) => axios({
    headers,
    ...(uri ? { url: uri } : { baseURL, url: endpoint }),
    ...(method.toUpperCase() === 'GET' ? { params } : { method, params, data }),
  }),
  uploadFile: upload,
  uploadFiles: async ({
    files, cb, url = API_HOST, headers = [{ key: '', value: '' }], existedList = [], maxLength = Infinity,
  }) => {
    const filenames = await Promise.all(
      Array.from(files).slice(0, maxLength).map(file => upload({
        file, url, headers,
      })),
    );
    cb([
      ...existedList,
      ...filenames,
    ].slice(0, maxLength));
  },
};
