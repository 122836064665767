import 'normalize.css';
import './style.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import _get from 'lodash/get';
import Routes from '@/routes';
import store, { history } from '@/store';
import {
  resetFromLocalStorage,
  requestUserInfo,
} from '@/operations/account';
import {
  requestGameInitInfo,
} from '@/operations/game';

resetFromLocalStorage();
requestGameInitInfo({});
if (_get(store.getState(), 'Account.tokenInfo')) {
  requestUserInfo({});
}

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Routes />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root'),
);
