import store from '@/store';
import {
  accountLogout,
  resetAccountInfoFromLocalStorage,
  requestEmailLogin,
  requestFacebookLogin,
  requestGoogleLogin,
  requestVerifyLineAuthCode,
  requestLineLogin,
  requestVerifyAppleAuthCode,
  requestAppleLogin,
  requestUserInfo as _requestUserInfo,
  requestSetGameOneType as _requestSetGameOneType,
  requestCheckIn as _requestCheckIn,
} from '@/store/actions/account';
import { getItem, removeItem } from '../utils/storageHelper';

const { dispatch } = store;

export const logout = () => {
  dispatch(accountLogout());
};

export const resetFromLocalStorage = async () => {
  dispatch(resetAccountInfoFromLocalStorage());
};

const LOGIN_SUCCESS = (data) => {
  const redirectUrl = getItem('redirectUrl');
  removeItem('redirectUrl');
  window.location = redirectUrl || '/result#1';
};

const LOGIN_FAILED = (error) => {
  switch (error.message.apiResMsg) {
    case '["Confirmed":0]': {
      if (window.confirm('此帳號未完成確認，要跳轉到確認頁面嗎？')) {
        window.location = error.message.redirectUrl;
      }
      break;
    }
    case '["Confirmed":2]': {
      window.location = '/login#2';
      break;
    }
    case '["Confirmed":3]': {
      if (window.confirm('此帳號未完成註冊流程，要繼續進行註冊流程嗎？')) {
        window.location = error.message.redirectUrl;
      }
      break;
    }
    case '["Confirmed":4]': {
      window.location = '/login#4';
      break;
    }
    default: {
      if (error.message.redirectUrl) {
        window.location = error.message.redirectUrl;
      } else {
        window.location = '/login#2';
      }
    }
  }
};

export const emailLogin = async ({
  loginId,
  password,
  onSuccess = LOGIN_SUCCESS,
  onFailed = LOGIN_FAILED,
}) => {
  await dispatch(requestEmailLogin({
    loginId,
    password,
    onSuccess,
    onFailed,
  }));
};

export const facebookLogin = async ({
  loginId,
  onSuccess = LOGIN_SUCCESS,
  onFailed = LOGIN_FAILED,
}) => {
  await dispatch(requestFacebookLogin({
    loginId,
    onSuccess,
    onFailed,
  }));
};

export const googleLogin = async ({
  loginId,
  onSuccess = LOGIN_SUCCESS,
  onFailed = LOGIN_FAILED,
}) => {
  await dispatch(requestGoogleLogin({
    loginId,
    onSuccess,
    onFailed,
  }));
};

export const verifyLineAuthCode = async ({
  code,
  onSuccess = () => {},
  onFailed = () => {},
}) => {
  await dispatch(requestVerifyLineAuthCode({
    code,
    onSuccess,
    onFailed,
  }));
};

export const lineLogin = async ({
  loginId,
  onSuccess = LOGIN_SUCCESS,
  onFailed = LOGIN_FAILED,
}) => {
  await dispatch(requestLineLogin({
    loginId,
    onSuccess,
    onFailed,
  }));
};

export const verifyAppleAuthCode = async ({
  code,
  onSuccess = () => {},
  onFailed = () => {},
}) => {
  await dispatch(requestVerifyAppleAuthCode({
    code,
    onSuccess,
    onFailed,
  }));
};

export const appleLogin = async ({
  loginId,
  onSuccess = LOGIN_SUCCESS,
  onFailed = LOGIN_FAILED,
}) => {
  await dispatch(requestAppleLogin({
    loginId,
    onSuccess,
    onFailed,
  }));
};

export const requestUserInfo = async ({
  onSuccess,
  onFailed,
}) => {
  await dispatch(_requestUserInfo({ onSuccess, onFailed }));
};

export const requestSetGameOneType = async ({
  type,
  onSuccess,
  onFailed,
}) => {
  await dispatch(_requestSetGameOneType({
    type,
    onSuccess,
    onFailed,
  }));
};

export const requestCheckIn = async ({
  landmarkId,
  onSuccess,
  onFailed,
}) => {
  await dispatch(_requestCheckIn({
    landmarkId,
    onSuccess,
    onFailed,
  }));
};
export default null;
