import { Types } from '@/store/actions/articles';

const initState = {
  knowledgeList: [],
  announceList: [],
};

export default (state = initState, action) => {
  switch (action.type) {
    case Types.KNOWLEDGE_LIST_SUCCESS: {
      return {
        ...state,
        knowledgeList: action.payload.data,
      };
    }
    case Types.KNOWLEDGE_LIST_FETCHING:
    case Types.KNOWLEDGE_LIST_FAILED: {
      return { ...state, knowledgeList: [] };
    }
    case Types.ANNOUNCE_LIST_SUCCESS: {
      return {
        ...state,
        announceList: action.payload.data,
      };
    }
    case Types.ANNOUNCE_LIST_FETCHING:
    case Types.ANNOUNCE_LIST_FAILED: {
      return { ...state, announceList: [] };
    }
    default:
      return state;
  }
};
