import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import Account from './Account';
import Articles from './Articles';
import Game from './Game';

export default history => combineReducers({
  router: connectRouter(history),
  Account,
  Articles,
  Game,
});
